import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationService } from '../shared/services/configuration.service';
import { TaskModel } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private baseUrl: string = `${this.configSvc.timeTrackerApiUrl}/task`;

  constructor(private http: HttpClient, private configSvc: ConfigurationService) { }

  getTasks(listId: string, assignees?: string): Observable<Array<TaskModel>> {
    var filter = assignees ? `?filter[0].spec=assignees[]&filter[0].value=${assignees}` : '';
    return this.http.get<Array<TaskModel>>(`${this.baseUrl}/${listId}${filter}`);
  }

  private createQuery(params?: any): string {
    var query = '';

    if (params) {
      Object.keys(params).forEach(prop => {
        if (params[prop]) {
          if (params[prop] instanceof Array) {
            (params[prop] as number[]).forEach(x => {
              query += `${prop}=${x}&`
            })
          } else {
            query += `${prop}=${params[prop]}&`
          }
        }
      });
    }

    return query.slice(0, -1);
  }

}