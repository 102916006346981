import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { UserModel } from 'src/app/models/user.model';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class UserService {

    private baseUrl: string = `${this.configSvc.clickUpApiUrl}`;
    private _isLoggedIn: boolean = false;
    private _permisions?: string[];
    public _user?: UserModel;
    isLoggedInChange: Subject<boolean> = new Subject<boolean>();

    constructor(private http: HttpClient, private configSvc: ConfigurationService) {
        const token = localStorage.getItem("access_token");

        if (token) {
            this.getUser().subscribe(data => {
                this._user = data.user;
            });
            this._isLoggedIn = true;
        }
    }

    set isLoggedIn(val: boolean) {
        this._isLoggedIn = val;
    }

    get isLoggedIn() {
        return this._isLoggedIn;
    }

    logout() {
        localStorage.clear();
        this._isLoggedIn = false;
        localStorage.removeItem("access_token");
        // this.auth.logout({ returnTo: environment.auth.returnTo });
    }

    getUser(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/user`);
    }
}
