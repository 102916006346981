import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TrackTimeComponent } from './components/schedule/track-time/track_time.component';
import { ConfigurationService } from './shared/services/configuration.service';
import { UserService } from './shared/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  toggledSidebar: boolean = true;
  selected: string = '';
  selectedGroup: string = '';
  @ViewChild('drawer') drawer!: MatSidenav;
  breadcrumbList: Array<any> = [];
  // showHeader: boolean = false;
  routeData: any;
  secondaryMenu: any[] = [];
  allSuppliers: any[] = [];

  @ViewChild(TrackTimeComponent, { static: false }) scheduleTasksFilterComponent!: TrackTimeComponent;

  constructor(
    public userSvc: UserService,
    private router: Router,
    private configSvc: ConfigurationService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  
  logout() {
    this.userSvc.logout();
  }
}
