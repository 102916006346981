import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { authService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationGuard implements CanActivate {


    constructor(private router: Router, private usrSvc: UserService, private authSrv: authService) { }

    canActivate(): Observable<boolean> | boolean {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramValue = urlParams.get('code');

        if (!localStorage.getItem("access_token")) {

            if (paramValue) {
                this.authSrv.getToken(paramValue).subscribe(data => {
                    var token = data;
                    localStorage.setItem("access_token", token.accessToken);
                    this.usrSvc.isLoggedIn = true;
                    this.router.navigate(['/TrackedTimeCalendar']);
                    return true;
                })
            } else {
                window.location.href = `${environment.auth.login}?client_id=${environment.auth.clientId}&redirect_uri=${environment.auth.redirectUri}`;
            }
            return false;
        }

        return true;
    }
}