import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
// import { MdePopoverModule } from '@material-extended/mde';

import { SharedRoutingModule } from './shared-routing.module';
import { ConfigurationService } from './services/configuration.service';
import { MaterialModule } from '../core/material.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import localeEsAR from '@angular/common/locales/es-AR';
import localeEsARExtra from '@angular/common/locales/extra/es-AR';
import { UserService } from './services/user.service';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { ToastComponent } from './components/toast/toast.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

registerLocaleData(localeEsAR, 'es', localeEsARExtra);

@NgModule({
  declarations: [
    EnumToArrayPipe,
    ToastComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    MatCardModule,
    NgbCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatMenuModule
  ],
  exports: [
    MaterialModule,
    NgbCarouselModule,
    EnumToArrayPipe,
    FormsModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule
  ],
  providers: [
    ConfigurationService,
    UserService,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'es-Ar'
    },
  ]
})
export class SharedModule { }
