import { Injectable } from '@angular/core';
import { TimeEntriesModel } from '../models/time_entries.model';

@Injectable({
  providedIn: 'root'
})
export class TimeModelService {

  constructor() { }

  addMilliseconds(timestampStr: string, millisecondsToAddStr: string): string {
    const timestamp = parseInt(timestampStr, 10);
    const millisecondsToAdd = parseInt(millisecondsToAddStr, 10);
    const resultTimestamp = timestamp + millisecondsToAdd;

    return resultTimestamp.toString();
  }

  convertToMidnight(millisecondsStr: string): string {
    const milliseconds = parseInt(millisecondsStr, 10);
    const date = new Date(milliseconds);
    date.setHours(0, 0, 0, 0);

    return date.getTime().toString();
  }

  getStartAndEndOfMonth(startDate?: Date, endDate?: Date, currentDate?: Date): { startDate: number, endDate: number } {
    if (startDate && endDate) {
      return {
        startDate: startDate.getTime(),
        endDate: endDate.getTime()
      };
    }

    if (!currentDate) {
      currentDate = new Date();
    }

    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    firstDayOfMonth.setHours(0, 0, 0, 0);

    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    lastDayOfMonth.setHours(23, 59, 59, 999);

    return {
      startDate: firstDayOfMonth.getTime(),
      endDate: lastDayOfMonth.getTime()
    };
  }

  calculateHours(itemArray: TimeEntriesModel[]) {
    let cost: number = itemArray
      .reduce(function (a, b) {
        return a + Number(b.duration);
      }, 0);

    return cost / 1000 / 60 / 60;
  }

  convertTimeStampStrToDate(timeStamp: string){
    return new Date(parseInt(timeStamp));
  }

  getStartTask(date: Date) {
    date.setHours(4, 0, 0, 0);
    return date.getTime();
  }

  getEndTask(timestamp: number, hoursToAdd: number, minutesToAdd: number): number {
    const date = new Date(timestamp);
    date.setHours(date.getHours() + hoursToAdd);
    date.setMinutes(date.getMinutes() + minutesToAdd);
    return date.getTime();
  }

  convertHoursAndMinutesToMilliseconds(hours: number, minutes: number): number {
    const millisecondsInAnHour = 60 * 60 * 1000; // 1 hora en milisegundos
    const millisecondsInAMinute = 60 * 1000; // 1 minuto en milisegundos

    const hoursInMilliseconds = hours * millisecondsInAnHour;
    const minutesInMilliseconds = minutes * millisecondsInAMinute;

    const totalMilliseconds = hoursInMilliseconds + minutesInMilliseconds;

    return totalMilliseconds;
  }

  convertTimestampToHoursAndMinutes(timestamp: string): { hours: number, minutes: number } {
    const timestampNumber = parseInt(timestamp, 10);
    const hours = Math.floor(timestampNumber / 3600000);
    const minutes = Math.floor((timestampNumber % 3600000) / 60000);

    return { hours, minutes };
  }
}
