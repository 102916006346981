<div class="global-container container-fluid form spinner-container">
  <div class="col-1 filter-position-content-reference">
  </div>
  <div class="row">
    <div class="custom-col-xxl-12">
      <div class="form content-calendar container-fluid">
        <div class="row text-center calendar">
          <div class="col-lg-4">
            <h3>{{ viewDate | calendarDate:(view + 'ViewTitle') | titlecase}}</h3>
          </div>
          <div class="col-lg-8">
            <div class="float-right" style="margin-right: 20px;">
              <div class="btn-selector-view">
                <div class="float-left" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay(viewDate,view)">
                  <img class="cursor-pointer" title="Anterior" src="../../../../../assets/icons/common/back.svg">
                </div>
                <div class="float-right" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay(viewDate,view)">
                  <img class="cursor-pointer" title="Siguiente" src="../../../../../assets/icons/common/next.svg">
                </div>
              </div>
              <mat-form-field appearance="outline" class="selector-view">
                <mat-select [(value)]="defaultCalendarView">
                  <mat-option (click)="setView(CalendarView.Month)" value="Month">
                    Monthly
                  </mat-option>
                  <!-- <mat-option (click)="setView(CalendarView.Week)" value="Week">
                    Weekly
                  </mat-option>
                  <mat-option (click)="setView(CalendarView.Day)" value="Day">
                    Daily
                  </mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <br />
        
        <ng-template #customCellTemplate let-day="day" let-locale="locale">
          <div class="cal-cell-top calendar">
            <div class="mt-3" [matBadgeHidden]="day.events.length == 0" matBadge="{{timeModelSrv.calculateHours(day.events)}} Hours" matBadgeColor="warn">
              <span class="custom-cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <div *ngFor="let event of day.events; index as i">
              <div *ngIf="10 > i">
                <app-item-template [data]="event" [height]="15" [event]="event" [view]="view"></app-item-template>
              </div>
            </div>
            <div class="row pt-1 pb-1 more-events" *ngIf="day.events.length > 10">
              <b>+ 10 eventos</b>
            </div>
          </div>
        </ng-template>
        
        <ng-template #customDayWeekTemplate let-weekEvent="weekEvent" let-daysInWeek="daysInWeek" let-events="events"
          let-eventClicked="eventClicked">
          <app-item-template [data]="weekEvent.event.event.data" [height]="weekEvent.height" [weekEvent]="weekEvent"
            [daysInWeek]="daysInWeek" [events]="events" [eventClicked]="eventClicked" [event]="weekEvent.event.event" [view]="view"></app-item-template>
        </ng-template>
        
        <div [ngSwitch]="view">
          <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [cellTemplate]="customCellTemplate"
            (dayClicked)="openListDialogAction($event)"  (beforeViewRender)="applyDateSelectionPolicy($event)">
          </mwl-calendar-month-view>
        
          <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" 
            [eventTemplate]="customDayWeekTemplate" [excludeDays]="excludeDays">
          </mwl-calendar-week-view>
        
          <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" 
            [eventTemplate]="customDayWeekTemplate">
          </mwl-calendar-day-view>
        </div>
      </div>
    </div>
  </div>
</div>