import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationService } from '../shared/services/configuration.service';
import { SpaceModel } from '../models/space.model';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {

  private baseUrl: string = `${this.configSvc.timeTrackerApiUrl}/space`;

  constructor(private http: HttpClient, private configSvc: ConfigurationService) { }

  getFolders(spaceId: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.baseUrl}/space/${spaceId}/folder`);
  }

  getSpace(teamId: string): Observable<Array<SpaceModel>> {
    return this.http.get<Array<SpaceModel>>(`${this.baseUrl}/${teamId}`);
  }
}
