import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-item-template',
  templateUrl: './item-template.component.html',
  styleUrls: ['./item-template.component.scss']
})
export class ItemTemplateComponent  implements OnInit {

  constructor(private el: ElementRef) { }

  @Input() view: string = 'month';
  @Input() data: any;
  @Input() weekEvent: any;
  @Input() daysInWeek: any;
  @Input() events: any;
  @Input() event: any;
  @Input() eventClicked: any;
  @Input() height!: number;

  @Output() openDialogEvent = new EventEmitter<any>();

  ngOnInit(): void {
    var test = this.data;
  }

  dialogAction(): void {
    this.openDialogEvent.emit();
  }

  convertMillisecondsToTimeFormat(milliseconds: number): string {
    // Calculate hours, minutes, and seconds
    const totalSeconds = Math.floor(milliseconds / 1000);
    const seconds = totalSeconds % 60;
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const hours = Math.floor(totalSeconds / 3600);
  
    var formattedTime = '';
    // Format the result
  
    if (minutes == 0 && seconds == 0) {
      formattedTime = `${hours}H`;
    } else {
      if (seconds == 0) {
        formattedTime = `${hours}H ${minutes}M`;
      } else {
        formattedTime = `${hours}H ${minutes}M ${seconds}S`;
      }
    }
    return formattedTime;
  }
}
