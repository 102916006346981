import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const ContainerAnimations = {
    height: trigger('Expand-Collapse', [
        state('collapse', style({ 'height': '0px', 'opacity': '0', 'display': 'none' })),
        state('expand', style({ 'opacity': '1' })),

        transition('collapse => expand', [
            style({ 'display': 'block' }),
            animate('500ms ease-in')
        ]),

        transition('expand => collapse', [
            animate('500ms ease-in')
        ])
    ])
}

export const DetailAnimations = {
    height: trigger('Expand-Collapse', [
        state('collapse', style({ 'height': '0px', 'opacity': '0', 'display': 'none' })),
        state('expand', style({ 'opacity': '1' })),

        transition('collapse => expand', [
            style({ 'display': 'block' }),
            animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
        ]),

        transition('expand => collapse', [
            animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
        ])
    ])
}

export const ContainerAnimationsWithoutTransition = {
    height: trigger('Expand-Collapse', [
        state('collapse', style({ 'height': '0px', 'opacity': '0', 'display': 'none' })),
        state('expand', style({ 'opacity': '1' })),
    ])
}

export const ToastAnimation = {
    show: trigger('flyInOut', [
        state('inactive', style({
            opacity: 1,
        })),
        transition('inactive <=> active', animate('500ms ease-out', keyframes([
            style({
                transform: 'translateX(340px)',
                offset: 0,
                opacity: 0,
            }),
            style({
                offset: .7,
                opacity: 1,
                transform: 'translateX(-20px)'
            }),
            style({
                offset: 1,
                transform: 'translateX(0)',
            })
        ]))),
        transition('active => removed', animate('500ms ease-in', keyframes([
            style({
                transform: 'translateX(-20px)',
                opacity: 1,
                offset: .2
            }),
            style({
                opacity: 0,
                transform: 'translateX(340px)',
                offset: 1
            })
        ])))
    ])
}