export const environment = {
  production: false,
  auth: {
    login: "https://app.clickup.com/api",
    clientId: '1PRPJZLEIQN4P3R1BVPW6O30LQVP6PJF',
    // clientId: "K4WJBDOJFA0RQYVPEMYJ0BCEU3F6TVNT", // to http://localhost:4200/
    redirectUri: window.location.origin
  },
  
  clickUpApiUrl: "https://api.clickup.com/api/v2",
  timeTrackerApiUrl: "https://api-tracker.engbimsoftware.com/api",
};