<div class="toaster">
    <div>
        <mat-icon *ngIf="toastType == 'toast-success'" class="color-green">check_circle</mat-icon>
        <mat-icon *ngIf="toastType == 'toast-error'" class="color-red">cancel</mat-icon>
    </div>
    <div class="toast-details">
        <div>
            <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
                {{ title }}
            </div>
            <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="custom-message"
                [class]="options.messageClass" [innerHTML]="message">
            </div>
            <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="custom-message"
                [class]="options.messageClass" [attr.aria-label]="message">
                {{ message }}
            </div>
        </div>
    </div>
    <div>
        <div class="toast-close-button-container" (click)="remove()">
            <mat-icon *ngIf="options.closeButton">close</mat-icon>
        </div>
    </div>
</div>