<header class="header">
  <div class="primary ng-star-inserted">
    <div class="row">
      <div class="col-1">
        <img src="assets/logos/ENG_Logo.png" alt="Eng logo">
      </div>
      <div class="col-11">
        <div class="row mainMenu">
          <div class="col-10">
            <div class="row">
              <mat-list class="inline-list">
                <ng-container>
                  <mat-list-item>
                    <img src="../assets/icons/menu/calendar.svg">
                    <span class="navigation-item-label">Tracked Time Calendar</span>
                  </mat-list-item>
                </ng-container>
              </mat-list>
            </div>
          </div>
          <div class="col-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="time-tracker-sidenav-content scrollable-content w-100 text-center m-0 main-container">
  <div class="row">
    <router-outlet></router-outlet>
  </div>
</div>