import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  constructor(public toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  options!: GlobalConfig;
  private lastInserted: number[] = [];

  toastSuccess: string = 'toast-success';
  toastError: string = 'toast-error';
  toastInfo: string = 'toast-info';
  toastWarning: string = 'toast-warning';

  success(title: string, message: string) {
    this.openToast(title, message, this.toastSuccess);
  }

  error(title: string, message: string) {
    this.openToast(title, message, this.toastError);
  }

  info(title: string, message: string) {
    this.openToast(title, message, this.toastInfo);
  }

  warning(title: string, message: string) {
    this.openToast(title, message, this.toastWarning);
  }

  openToast(title: string, message: string, type: string) {
    const opt = cloneDeep(this.options);
    opt.closeButton = true;
    opt.positionClass = 'toast-top-right';
    opt.timeOut = 7500;
    opt.newestOnTop = false;
    // opt.disableTimeOut = true;
    const inserted = this.toastr.show(
      message,
      title,
      opt,
      type,
    );
    if (inserted) {
      this.lastInserted.push(inserted.toastId);
    }
    return inserted;
  }
}
