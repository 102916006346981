import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationService } from '../shared/services/configuration.service';
import { ListModel } from '../models/list.model';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  private baseUrl: string = `${this.configSvc.timeTrackerApiUrl}/list`;

  constructor(private http: HttpClient, private configSvc: ConfigurationService) { }

  getLists(teamId: string | undefined, spaceId: string): Observable<Array<ListModel>> {
    return this.http.get<Array<ListModel>>(`${this.baseUrl}/GetLists/${teamId}/${spaceId}`);
  }
}
