import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ListModel } from 'src/app/models/list.model';
import { SpaceModel } from 'src/app/models/space.model';
import { TaskModel } from 'src/app/models/task.model';
import { TeamsModel } from 'src/app/models/team.model';
import { TimeEntriesRequestModel } from 'src/app/models/time-entries-post.model';
import { TimeEntriesModel } from 'src/app/models/time_entries.model';
import { SpaceService } from 'src/app/services/space.service';
import { TaskService } from 'src/app/services/task.service';
import { TeamsService } from 'src/app/services/teams.service';
import { TimeModelService } from 'src/app/services/time-model.service';
import { TimeService } from 'src/app/services/time.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ListService } from 'src/app/services/list.service';
import { Guid } from 'guid-typescript';
import { SpaceGroupModel } from 'src/app/models/space-group.model';
import { ListGroupModel } from 'src/app/models/list-group.model';
import { TaskGroupModel } from 'src/app/models/task-group.model';
import { forEach } from 'lodash';

@Component({
  selector: 'app-track-time-list-modal',
  templateUrl: './track-time-list-modal.component.html',
  styleUrls: ['./track-time-list-modal.component.scss']
})
export class TrackTimeListModalComponent implements OnInit {
  data: any = {};
  events: any = [];

  gridData: FormGroup[] = [];
  teamList: TeamsModel[] = [];

  spacesGroup: SpaceGroupModel[] = [];
  listsGroup: ListGroupModel[] = [];
  tasksGroup: TaskGroupModel[] = [];

  @ViewChild('minutesInput') minutesInput!: ElementRef;

  constructor(private timeSvc: TimeService,
    private teamSrv: TeamsService,
    private spaceSrv: SpaceService,
    private taskSrv: TaskService,
    private listSrv: ListService,
    private toastSrv: ToastService,
    public timeModelSrv: TimeModelService,
    private userSrv: UserService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<TrackTimeListModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public eventData: any,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.data = this.eventData.event;
    this.events = this.eventData.event.events;

    this.getTeams();
    this.initTasksList();
    this.addRow();
  }

  initTasksList() {
    this.data.events.forEach((event: TimeEntriesModel, index: number) => {

      this.gridData.push(this.createFormGroup(event));

      this.getSpaces(this.gridData[index].controls.team.value, event.taskLocation)
      this.getFolders(this.gridData[index].controls.space.value, this.gridData[index])
      this.getTasks(this.gridData[index].controls.list.value, this.gridData[index])

      this.setValueChangeAction(index);

    })
  }

  getTeams() {
    this.teamSrv.getTeams().subscribe((data: TeamsModel[]) => {
      this.teamList = data;
    })
  }

  getSpaces(teamId: string, initData?: any) {
    if (!this.findSpaces(teamId)) {
      this.spaceSrv.getSpace(teamId).subscribe((data: SpaceModel[]) => {

        var sharedId = '0';
        if (initData) {
          if (!data.find(x => x.id == initData.space)) {
            sharedId = initData.space;
          }
        }

        data.unshift({ id: sharedId, name: 'Shared with me', teamId: teamId, avatar: '', color: '' })
        this.spacesGroup.push({ teamId: teamId, spaces: data });
      })
    }
  }

  findSpaces(row: any): any {
    if (this.spacesGroup.length > 0) {
      var result = this.spacesGroup.find(x => x.teamId == row.controls.team.value);
      if (result) {
        return result.spaces
      }
    }
  }

  getFolders(spaceId: string, row: any) {

    if (!this.findListsBySpaceId(spaceId)) {
      let teamId = row.controls.team.value;

      this.listSrv.getLists(teamId, spaceId).subscribe((data: ListModel[]) => {
        this.listsGroup.push({ spaceId: spaceId, Lists: data });
      })
    }
  }

  findLists(row: any): any {
    if (this.listsGroup.length > 0) {
      var result = this.listsGroup.find(x => x.spaceId == row.controls.space.value);
      if (result) {
        return result.Lists
      }
    }
  }

  findListsBySpaceId(spaceId: string): any {
    if (this.listsGroup.length > 0) {
      var result = this.listsGroup.find(x => x.spaceId == spaceId);
      if (result) {
        return result.Lists
      }
    }
  }

  getTasks(listId: any, row: any) {

    if (!this.findTasks(row)) {
      var usertoFilterPTO = this.validatePTO(listId, row);
      this.taskSrv.getTasks(listId, usertoFilterPTO).subscribe((data: TaskModel[]) => {
        if (usertoFilterPTO) {
          data.forEach((x: TaskModel) => {
            x.name = `${x.name} - ${this.datepipe.transform(this.timeModelSrv.convertTimeStampStrToDate(x.startDate), 'yyyy/MM/dd')}`
          })
        }
        this.tasksGroup.push({ listId: listId, tasks: data });
      })
    }
  }


  findTasks(row: any): any {
    if (this.tasksGroup.length > 0) {
      var result = this.tasksGroup.find(x => x.listId == row.controls.list.value);
      if (result) {
        return result.tasks;
      }
    }
  }

  validatePTO(listId: any, row: any) {
    var listName = this.findLists(row)?.find((x: ListModel) => x.id == listId)?.name;
    var userId: any = '';
    if (listName && listName.includes('PTO')) {
      userId = this.userSrv._user?.id.toString();
    }

    return userId;
  }

  setValueChangeAction(index: number) {
    this.gridData[index].valueChanges.subscribe(value => {
      if (!value.updated) {
        this.gridData[index].controls.updated.patchValue(true);
      }
    });
  }

  save() {
    this.gridData.forEach((row: any, index: number) => {
      if (this.canSave(index)) {
        this.saveRow(index);
      }
    });
  }

  saveRow(index: number): void {

    this.gridData[index].disable();
    const rowData = this.gridData[index].value;
    rowData.minutes = rowData.minutes == '' ? 0 : rowData.minutes;

    var start = this.timeModelSrv.getStartTask(this.data.date);
    let time = this.timeModelSrv.convertHoursAndMinutesToMilliseconds(rowData.hours, rowData.minutes);
    var entry: TimeEntriesRequestModel =
    {
      start: start,
      duration: time,
      description: 'From app',
      billable: rowData.billable,
      createdWith: 'api',
      tid: rowData.task
    };

    if (!rowData.id) {
      this.insertTimeEntry(index, entry, rowData);
    } else {
      this.updateTimeEntry(index, entry, rowData);
    }
  }

  insertTimeEntry(index: number, entry: TimeEntriesRequestModel, rowData: any) {
    
    let list = this.findListsBySpaceId(rowData.space);
    let listName = list.find((x: ListModel) => x.id == rowData.list).name;

    this.timeSvc.postTimeEntry(rowData.team, entry).subscribe({
      next: (data: TimeEntriesModel) => {
        this.gridData[index].controls.id.patchValue(data.id);
        this.gridData[index].enable();
        this.setValueChangeAction(index);
        this.fixForCalendarInsertion(rowData, data);

        this.toastSrv.success('Time tracker', `The time for ${listName} - ${this.data.events[index].task.name} is registered successfully.`);
      },
      error: (error) => {
        this.gridData[index].enable();
        this.toastSrv.error('Time tracker', `Error creating time for ${listName} - ${this.data.events[index].task.name}: ` + error);
      }
    });
  }

  fixForCalendarInsertion(rowData: any, data: any) {
    data.duration = data.duration.toString();
    data.start = this.timeModelSrv.convertTimeStampStrToDate(data.start);
    data.end = this.timeModelSrv.convertTimeStampStrToDate(data.end);
    data.taskLocation.list = rowData.list;
    data.taskLocation.space = rowData.space;
    data.teamId = rowData.team;

    let list = this.findListsBySpaceId(rowData.space);
    data.listName = list.find((x: ListModel) => x.id == rowData.list).name;

    this.data.events.push(data);
  }

  updateTimeEntry(index: number, entry: TimeEntriesRequestModel, rowData: any) {
    
    let list = this.findListsBySpaceId(rowData.space);
    let listName = list.find((x: ListModel) => x.id == rowData.list).name;

    this.timeSvc.putTimeEntry(rowData.team, rowData.id, entry).subscribe({
      next: (response: any) => {
        this.data.events[index] = response[0];
        this.data.events[index].teamId = rowData.team;
        
        this.data.events[index].listName = listName;

        this.gridData[index].enable();
        this.gridData[index].controls.updated.setValue(false, { emitEvent: false });
        this.toastSrv.success('Time tracker', `The time for ${listName} - ${this.data.events[index].task.name} is updated successfully.`);
      },
      error: (error) => {
        this.gridData[index].enable();
        this.toastSrv.error('Time tracker', `Error updated time for ${listName} - ${this.data.events[index].task.name}: ` + error);
      }
    });
  }

  removeTimeEntry(index: number): void {

    var id = this.gridData[index].controls.id.value;
    var teamId = this.gridData[index].controls.team.value;
    
    let list = this.findListsBySpaceId(this.gridData[index].value.space);
    let listName = list.find((x: ListModel) => x.id == this.gridData[index].value.list).name;

    let taskName = this.data.events[index].task.name;

    if (id) {
      this.gridData[index].disable();
      this.timeSvc.deleteTimeEntry(teamId, id).subscribe({
        next: () => {
          this.data.events.splice(index, 1)
          this.cleanLists(index);
          this.toastSrv.success('Time tracker', `The time for ${listName} - ${taskName} is removed successfully.`);
        },
        error: (error) => {
          this.gridData[index].enable();
          this.toastSrv.error('Time tracker', `The time for ${listName} - ${taskName} cannot be deleted successfully.`);
        }
      });
    } else {
      this.toastSrv.success('Time tracker', `The time is removed successfully.`);
      this.cleanLists(index);
    }
  }

  addRow(): void {
    const newRow = this.createFormGroup();
    this.gridData.push(newRow);
  }

  createFormGroup(timeModel?: TimeEntriesModel) {

    if (timeModel) {
      let time = this.timeModelSrv.convertTimestampToHoursAndMinutes(timeModel.duration)

      return new FormGroup({
        rowId: new FormControl(Guid.create()),
        id: new FormControl(timeModel.id),
        team: new FormControl(timeModel.teamId, Validators.required),
        space: new FormControl(timeModel.taskLocation.space, Validators.required),
        list: new FormControl(timeModel.taskLocation.list, Validators.required),
        task: new FormControl(timeModel.task.id, Validators.required),
        hours: new FormControl(time.hours == 0 ? '' : time.hours, [Validators.min(0), Validators.max(99)]),
        minutes: new FormControl(time.minutes == 0 ? '' : time.minutes, [Validators.min(0), Validators.max(59)]),
        billable: new FormControl(timeModel.billable),
        updated: new FormControl(false),
      }, { validators: this.requireOneField });
    }

    return new FormGroup({
      id: new FormControl(),
      team: new FormControl('', Validators.required),
      space: new FormControl('', Validators.required),
      list: new FormControl('', Validators.required),
      task: new FormControl('', Validators.required),
      hours: new FormControl('', [Validators.min(0), Validators.max(99)]),
      minutes: new FormControl('', [Validators.min(0), Validators.max(59)]),
      billable: new FormControl(false),
      updated: new FormControl(false),
    }, { validators: this.requireOneField });
  }

  cleanLists(index: number) {
    this.gridData.splice(index, 1);
  }

  validateInput(event: KeyboardEvent) {
    const inputValue = this.minutesInput.nativeElement.value;
    const newValue = inputValue + event.key;

    if (parseInt(newValue) > 59 || isNaN(parseInt(newValue))) {
      event.preventDefault();
    }
  }

  requireOneField(control: AbstractControl): { [key: string]: boolean } | null {
    const hours = control.get('hours');
    const minutes = control.get('minutes');

    if ((!hours || hours.value === null || hours.value === '' || hours.value === 0)
      && (!minutes || minutes.value === null || minutes.value === '' || minutes.value === 0)) {
      return { 'requireOneField': true };
    }

    return null;
  }

  validateSaveBtn() {
    var disabled = true;
    this.gridData.forEach((row: any, index: number) => {
      if ((!row.controls.id.value && row.valid) || (row.controls.updated.value && row.valid)) {
        disabled = false;
      }
    });
    return disabled;
  }

  canSave(index: number) {
    if ((this.gridData[index].controls.id.value == null && this.gridData[index].valid)
      || (this.gridData[index].controls.updated.value && this.gridData[index].valid))
      return true;

    return false;
  }
}
