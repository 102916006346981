<div class="schedule-details-general w-100 h-100 m-0 p-0 pb-5 scrollable-content">
    <div class="row dialog-header" *ngIf="data">
      <h1>
        <span class="dialog-header-title">Track Time -
          <span class="capitalize">{{data.date | date:'EEEE, MMMM d, y' }}</span>
        </span>
      </h1>
    </div>
    <div class="grid-container" *ngIf="gridData.length > 0">
      <!-- <form> -->
        <table class="grid">
          <thead>
            <tr>
              <th style="border-left: none;">Team</th>
              <th>Space</th>
              <th>List</th>
              <th>Task</th>
              <th>Time</th>
              <th>Billable</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of gridData; let i = index" [formGroup]="row">
              <td style="border-left: none;">
                <mat-form-field class="w-100">
                  <mat-label>Choose a team</mat-label>
                  <mat-select formControlName="team" (selectionChange)="getSpaces($event.value)">
                    <mat-option *ngFor="let team of teamList" [value]="team.id">{{team.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="w-100">
                  <mat-label>Choose a space</mat-label>
                  <mat-select formControlName="space" (selectionChange)="getFolders($event.value, row)">
                    <mat-option *ngFor="let space of findSpaces(row)" [value]="space.id">{{space.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="w-100">
                  <mat-label>Choose a list</mat-label>
                  <mat-select formControlName="list" (selectionChange)="getTasks($event.value,row)">
                    <mat-option *ngFor="let list of findLists(row)" [value]="list.id">{{list.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="w-100">
                  <mat-label>Choose a task</mat-label>
                  <mat-select formControlName="task">
                    <mat-option *ngFor="let task of findTasks(row)" [value]="task.id">{{task.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <div>
                  <mat-form-field style="margin-right: 10px; width: 70px;">
                    <mat-label>Hours</mat-label>
                    <input matInput type="number" formControlName="hours" placeholder="0" [max]="99" [min]="0"
                      onKeyPress="if(this.value.length==2) return false;">
                  </mat-form-field>
                  <mat-form-field style="margin-left: 10px; width: 70px;">
                    <mat-label>Minutes</mat-label>
                    <input matInput type="number" formControlName="minutes" placeholder="0" [max]="59" [min]="0"
                      #minutesInput (keydown)="validateInput($event)">
                  </mat-form-field>
                </div>
              </td>
              <td>
                <mat-checkbox formControlName="billable"></mat-checkbox>
              </td>
              <td>
                <i class="material-icons btnRemove" title="Remove time"  ng-disabled="!gridData[i].enabled" (click)="removeTimeEntry(i)">delete</i>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <button mat-raised-button class="custom-button float-right" (click)="save()" [disabled]="validateSaveBtn()">Save all</button>
              </td>
            </tr>
          </tbody>
        </table>
      <!-- </form> -->
  
      <button mat-raised-button class="custom-button" (click)="addRow()">Add row</button>
    </div>