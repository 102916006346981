import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationService } from '../shared/services/configuration.service';
import { TeamsModel } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private baseUrl: string = `${this.configSvc.timeTrackerApiUrl}/Team`;

  constructor(private http: HttpClient, private configSvc: ConfigurationService) { }

  getTeams(): Observable<Array<TeamsModel>> {
    return this.http.get<Array<TeamsModel>>(`${this.baseUrl}`);
  }

  getSharedLists(teamId: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.baseUrl}/SharedLists/${teamId}`);
  }
}
