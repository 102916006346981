import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TeamsModel } from 'src/app/models/team.model';
import { SpaceModel } from 'src/app/models/space.model';
import { ListModel } from 'src/app/models/list.model';
import { TaskModel } from 'src/app/models/task.model';
import { TimeEntriesModel } from 'src/app/models/time_entries.model';
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarMonthViewDay, CalendarView, } from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { TrackTimeListModalComponent } from '../track-time-list-modal/track-time-list-modal.component';
import { TimeService } from 'src/app/services/time.service';
import { TeamsService } from 'src/app/services/teams.service';
import { TimeModelService } from 'src/app/services/time-model.service';
import { groupBy } from 'lodash';

@Component({
  selector: 'app-track_time',
  templateUrl: './track_time.component.html',
  styleUrls: ['./track_time.component.scss']
})

export class TrackTimeComponent implements OnInit {

  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private timeSvc: TimeService,
    private teamSrv: TeamsService,
    public timeModelSrv: TimeModelService
  ) { }

  groupedData: any;
  teamList: TeamsModel[] = [];
  spaceList: SpaceModel[] = [];
  listList: ListModel[] = [];
  taskList: TaskModel[] = [];
  allEvents: any[] = [];

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  refresh = new Subject<void>();
  events: any[] = [];

  activeDayIsOpen: boolean = false;

  defaultCalendarView = 'Month'
  excludeDays: number[] = [0, 6];

  ngOnInit(): void {
    this.getTeams();
  }

  getTeams() {
    this.teamSrv.getTeams().subscribe((data: TeamsModel[]) => {
      this.teamList = data;
      this.teamList.forEach(x => {
        this.getTimeEntries(x.id);
      })
    })
  }

  getTimeEntries(teamId: string, startDate?: Date, endDate?: Date, currentDate?: Date) {
    var filter = this.timeModelSrv.getStartAndEndOfMonth(startDate, endDate, currentDate)
    this.timeSvc.getTimeEntries(teamId, filter.startDate, filter.endDate).subscribe((response: TimeEntriesModel[]) => {
      this.allEvents = [];
      response.forEach((x: TimeEntriesModel) => {
        x.teamId = teamId;
        x.start = this.timeModelSrv.convertToMidnight(x.start);
        x.end = this.timeModelSrv.addMilliseconds(x.start, x.duration);
        this.allEvents.push(x);
      });
      this.showAllTasks();
    })
  }

  showAllTasks() {
    this.allEvents.forEach(x => {
      x.start = this.timeModelSrv.convertTimeStampStrToDate(x.start);
      x.end = this.timeModelSrv.convertTimeStampStrToDate(x.end);
    })

    this.setEvents(this.allEvents);
  }

  openListDialogAction(calendarEvent: any): void {

    let CurrentDialog = this.dialog.open(TrackTimeListModalComponent, {
      minWidth: '85vw',
      maxWidth: '100vw',
      maxHeight: '95vh',
      panelClass: '.detailClass',
      data: { event: calendarEvent.day, },
    });
  }

  setEvents(events: any[]): void {
    this.events = events;
  }

  setView(view: CalendarView) {
    this.emitChange(view, this.viewDate);
    this.activeDayIsOpen = false;
    this.view = view;
  }

  closeOpenMonthViewDay(viewDate: Date, view: string) {
    this.emitChange(view, viewDate);
    this.activeDayIsOpen = false;
  }

  emitChange(view: string, viewDate: Date) {
    let from: Date | undefined = undefined;
    let to: Date | undefined = undefined;

    switch (view) {
      case "day": {
        from = startOfDay(viewDate);
        to = endOfDay(viewDate);
      }
        break;
      case "week": {
        from = startOfWeek(viewDate);
        to = endOfWeek(viewDate);
      }
        break;
      case "month": {
        from = startOfMonth(viewDate);
        to = endOfMonth(viewDate);
      }
        break;
    }

    this.teamList.forEach(t => {
      this.getTimeEntries(t.id, from, to);
    });
  }

  dateIsValid(date: Date): boolean {
    return date.getMonth() == this.viewDate.getMonth();
  }

  applyDateSelectionPolicy({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (!this.dateIsValid(day.date) || day.isWeekend) {
        day.cssClass = 'disabled-date';
      }
    });
  }
}
