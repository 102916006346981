import { Component } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'not-found404',
  templateUrl: './not-found404.component.html',
  styleUrls: ['./not-found404.component.scss']
})
export class PageNotFoundComponent {

  constructor(private usrSvc: UserService) { }

  logout() {
    this.usrSvc.logout();
  }
}
