<div class="item-content" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">
    <div *ngIf="view === 'month'">
        <div [style.height.px]="height" class="row day-event-box month-item">
            <div class="col-12 pl-1 pr-0 day-event-box text-left">
                <label style="color: rgb(163 163 163);">{{data.listName}}</label>
                <label>{{data.task.name}} - <span style="color: green;">{{convertMillisecondsToTimeFormat(data.duration)}}</span></label>
            </div>
        </div>
    </div>
    <div *ngIf="view != 'month'">
        <div [style.height.px]="height" class="float-left actveBorder">
        </div>
        <div [style.height.px]="height" class="day-event-box mb-1">
            <div class="day-event-box mb-1 text-left" (click)="eventClicked.emit({event: events})">
                <label class="truncate ml-1" style="color: rgb(163 163 163);">{{data.listName}}</label>
                <label class="truncate ml-1">{{data.task.name}} - <span style="color: green;">{{convertMillisecondsToTimeFormat(data.duration)}}</span></label>
            </div>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu" class="details-popover">

    <mat-card>
        <div class="summary-popover">
            <mat-card-header class="popover-header">
                <div class="row w-100">
                    <div class="col-12">
                        <div class="popover-header-title">
                            <label class="truncate" style="color: rgb(163 163 163);">{{data.listName}}</label>
                            <label class="truncate">{{data.task.name}}</label>
                        </div>
                    </div>
                </div>
            </mat-card-header>
            <mat-card-content class="popover-content">
                <div class="row">
                    This section is designed to show more details of the time tracked.
                </div>
            </mat-card-content>
            <mat-card-actions align="end" class="popover-action">
                <div class="pr-2">
                    <button mat-button class="custom-btn-grey custom-btn-primary"
                        (click)="menuTrigger.closeMenu()">Cerrar</button>
                </div>
            </mat-card-actions>
        </div>
    </mat-card>

</mat-menu>