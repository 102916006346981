import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()

export class ConfigurationService {

    private _environmentConfig: any;

    constructor() {
        this._environmentConfig = environment;
    } 
    
    get clickUpApiUrl(): string {
        return this._environmentConfig.clickUpApiUrl;
    }
    
    get timeTrackerApiUrl(): string {
        return this._environmentConfig.timeTrackerApiUrl;
    }
}