import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/layout/not-found404/not-found404.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

import { SharedModule } from './shared/shared.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationGuard } from './core/guards/auth-guard';
import { NgxMaskModule } from 'ngx-mask';
import { TrackTimeComponent } from './components/schedule/track-time/track_time.component';
import { ToastComponent } from './shared/components/toast/toast.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { TrackTimeListModalComponent } from './components/schedule/track-time-list-modal/track-time-list-modal.component';
import { ItemTemplateComponent } from './components/schedule/template/item-template.component';
import { TwoDigitDecimaNumberDirective } from './directives/TwoDigitDecimaNumberDirective';
import { AuthorizationInterceptor } from './core/interceptors/authorization-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    TrackTimeComponent,
    TrackTimeListModalComponent,
    ItemTemplateComponent,
    TwoDigitDecimaNumberDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    GoogleMapsModule,
    MatMenuModule,
    MatButtonModule,
    NgbRatingModule,
    NgxMaterialTimepickerModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot({showMaskTyped : true,}),
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ToastContainerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:4200'
    }),
    HttpClientModule,
    // AuthModule.forRoot({
    //   ...env.auth,
    //   // scope: env.auth.scope,
    //   // audience: env.auth.audience,
    //   httpInterceptor: {
    //     allowedList: [
    //       `${env.clickUpApiUrl}*`,
    //     ],
    //   }
    // }),
    NgxDocViewerModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthHttpInterceptor,
    //   multi: true,
    // },
    { provide: HTTP_INTERCEPTORS, 
      useClass: AuthorizationInterceptor, 
      multi: true 
    },
    { provide: LOCALE_ID, useValue: 'en-US' },
    AuthenticationGuard,
    DatePipe,
    TitleCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
