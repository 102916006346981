import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationService } from '../shared/services/configuration.service';
import { TimeEntriesRequestModel } from '../models/time-entries-post.model';
import { TimeEntriesModel } from '../models/time_entries.model';
import { TimeEntryLegacyModel } from '../models/time_entry_legacy_post.model';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  private baseUrl: string = `${this.configSvc.timeTrackerApiUrl}/time`;

  constructor(private http: HttpClient, private configSvc: ConfigurationService) { }

  getTimeEntries(teamId: string, startDate: number, endDate: number): Observable<Array<TimeEntriesModel>> {
    return this.http.get<Array<TimeEntriesModel>>(`${this.baseUrl}/${teamId}?startDate=${startDate}&endDate=${endDate}`);
  }

  postTimeEntry(teamId: string, timeEntry: TimeEntriesRequestModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${teamId}`, timeEntry);
  }

  deleteTimeEntry(teamId: string, timeEntryId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${timeEntryId}/${teamId}`);
  }

  putTimeEntry(teamId: string, timeEntryId: string, timeEntry: TimeEntriesRequestModel): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${teamId}/${timeEntryId}`, timeEntry);
  }
}
