import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var access_token = localStorage.getItem("access_token") as any

        const modifiedReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${access_token}`),
        });
        return next.handle(modifiedReq);
    }
}